<script>
    import SettingsWalletDeposit from './SettingsWalletDeposit.svelte';
    import WalletNav from '@/Components/WalletNav.svelte';
    import { setContext } from 'svelte';
    import { Info } from 'lucide-svelte';
    import * as Tabs from '$lib/components/ui/tabs';

    export let getLoggedUserAvailableAmount;
    export let balance_withdrawable;
    export let allowWithdrawals;
    export let authUser;

    export let paypal_client_id;
    export let paypal_secret;
    export let stripe_secret_key;
    export let coinbase_api_key;
    export let nowpayments_api_key;
    export let ccbillCredentialsProvided;
    export let paystack_secret_key;
    export let stripe_public_key;
    export let stripe_oxxo_provider_enabled;
    export let allow_manual_payments;
    export let offline_payments_iban;
    export let offline_payments_swift;
    export let offline_payments_bank_name;
    export let offline_payments_owner;
    export let offline_payments_account_number;
    export let offline_payments_routing_number;
    export let pointsPackages;

    export let withdrawal_allow_fees;
    export let withdrawal_default_fee_percentage;
    export let currency_symbol;
    export let pendingBalance;
    export let getWithdrawalAmountLimitations;
    export let getWithdrawalMinimumAmount;
    export let getWithdrawalsAllowedPaymentMethods;
    export let getWithdrawalMaximumAmount;
    export let errors = [];
    $: {
        const settingsWalletContext = {
            paypal_client_id,
            paypal_secret,
            stripe_secret_key,
            coinbase_api_key,
            nowpayments_api_key,
            ccbillCredentialsProvided,
            paystack_secret_key,
            stripe_public_key,
            stripe_oxxo_provider_enabled,
            allow_manual_payments,
            offline_payments_iban,
            offline_payments_swift,
            offline_payments_bank_name,
            offline_payments_owner,
            offline_payments_account_number,
            offline_payments_routing_number,
            pointsPackages,
            withdrawal_allow_fees,
            withdrawal_default_fee_percentage,
            currency_symbol,
            pendingBalance,
            getWithdrawalAmountLimitations,
            getWithdrawalMinimumAmount,
            getWithdrawalsAllowedPaymentMethods,
            getWithdrawalMaximumAmount,
            authUser,
        };
        setContext('settingsWalletContext', settingsWalletContext);
    }

    $: activeTab = 'deposit';

    function handleTab(tab) {
        activeTab = tab;
    }
</script>

<div class=" p-0">
    <div class="p-4">
        <svelte:component this="{WalletNav}" />
        <div id="wallet-div">
            <div class="inline-border-tabs mt-3">
                <!-- {{-- Paypal and stripe actual buttons --}} -->
                <div class="paymentOption paymentPP d-none">
                    <form id="wallet-deposit" method="post">
                        <input type="hidden" name="amount" id="wallet-deposit-amount" value="1" />
                        <input type="hidden" name="transaction_type" id="payment-type" value="" />
                        <input type="hidden" name="provider" id="provider" value="" />
                        <input type="hidden" name="manual_payment_files" id="manual-payment-files" value="" />
                        <input
                            type="hidden"
                            name="manual_payment_description"
                            id="manual-payment-description"
                            value=""
                        />

                        <button class="payment-button" type="submit"></button>
                    </form>
                </div>
                <div class="paymentOption paymentStripe d-none ml-2">
                    <button id="stripe-checkout-button">Checkout</button>
                </div>
                <div
                    class="balance relative flex w-full flex-col rounded-lg border border-transparent bg-gray-100 p-3 dark:bg-opacity-10 md:flex-row"
                >
                    <div class="mx-2 flex flex-grow items-center justify-center text-black dark:!text-gray-300">
                        <span class="mr-2 font-bold">Balance</span>
                        <div class="mr-6 flex items-center justify-center md:mr-0">
                            <span class="mr-2 text-3xl font-extrabold md:text-4xl">{getLoggedUserAvailableAmount}$</span
                            >
                            <!-- <span><PointLight /></span> -->
                        </div>
                    </div>
                    <div
                        class="md:text-normal absolute bottom-0 left-0 mx-2 flex h-full items-end justify-end gap-x-1 p-0 text-sm opacity-50 sm:!bottom-0 sm:!right-0 md:!right-0 md:!top-1/2 md:mr-4 md:translate-y-[-50%] md:transform md:p-2"
                    >
                        <div class="flex flex-row items-center justify-center p-0 md:p-2">
                            <span class="md:font-bold">Withdrawable:</span>
                            <span class="flex items-center justify-center gap-x-1"
                                >{balance_withdrawable}$ <Info class="mt-0.5 size-3" /></span
                            >
                        </div>
                    </div>
                </div>

                <div class="inline-border-tabs mt-3">
                    <Tabs.Root value="deposit" class="w-[100%]">
                        <Tabs.List class="w-[100%]">
                            <Tabs.Trigger class="w-[50%]" value="deposit">Deposit</Tabs.Trigger>
                            <Tabs.Trigger class="w-[50%]" value="withdraw">Withdraw</Tabs.Trigger>
                        </Tabs.List>
                        <Tabs.Content value="deposit">
                            <svelte:component this="{SettingsWalletDeposit}" />
                        </Tabs.Content>
                        <Tabs.Content value="withdraw">
                            <!-- <svelte:component this="{SettingsWalletWithdrawal}" /> -->
                            <div class="flex justify-center p-28">Coming Soon...</div>
                        </Tabs.Content>
                    </Tabs.Root>
                </div>
            </div>
        </div>
        <slot></slot>
    </div>
</div>

<!-- Use the errors prop somewhere in your component -->
{#if errors.length}
    <ul>
        {#each errors as error}
            <li>{error}</li>
        {/each}
    </ul>
{/if}
